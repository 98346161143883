<template>
  <v-app>
    <div
      style="height: 100%;display: flex;justify-content: center;align-items: center;padding: 10px"
      :style="{backgroundColor: this.$wikaz.getInstance().theme.colors.backgroundlogin}"
    >
      <v-card
        class="elevation-8"
        style="width: 400px"
      >
        <v-toolbar
          color="#456173"
          dense
        >
          <v-toolbar-title style="color: white">
            Identification - {{ $wikaz.getInstance().app.titre }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text align="center">
          <v-img
            contain
            width="100px"
            src="images/logo_wikaz.png"
          />
        </v-card-text>
        <v-card-text>
          <v-form
            ref="loginDetails"
            v-model="valid"
          >
            <v-text-field
              v-model="loginDetails.email"
              dense
              outlined
              label="Email"
              name="login"
              prepend-icon="person"
              type="text"
              :rules="emailRules"
            />
            <v-text-field
              id="password"
              v-model="loginDetails.password"
              dense
              outlined
              label="Mot de passe"
              name="password"
              prepend-icon="lock"
              type="password"
              @keydown.enter="loginPost"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div style="margin-left: 10px;font-size: 14px;color: grey">
            Version : {{ $wikaz.getInstance().app.version }}
          </div>
          <v-spacer />
          <v-btn
            :loading="loading"
            color="success"
            @click.prevent="loginPost"
          >
            SE CONNECTER
          </v-btn>
        </v-card-actions>
        <v-card-actions>
          <router-link :to="{ name: 'recovery' }">
            Mot de passe oublié?
          </router-link>
          <v-spacer style="width: 50px" />
        </v-card-actions>
      </v-card>
    </div>
  </v-app>
</template>
<script>
export default {
    name: 'VxLogin',
    data () {
        return {
            loginDetails: {
                email: '',
                password: '',
            },
            valid: true,
            emailRules: [
                v => !! v || 'E-mail est requis',
                v => /.+@.+\..+/.test(v) || 'E-mail non valide',
            ],
            loading: false,
        }
    },
    mounted () {
        this.$store.commit('CLEAR_ALL_DATA')
    },
    methods: {
        loginPost () {
            if (this.$refs.loginDetails.validate()) {
                this.loading = true
                this.$auth.login(this.loginDetails.email, this.loginDetails.password, 'home').then(() => {
                    this.loading = false
                    this.$wikaz.loadPhotosUsers()
                })
            } else {
                this.$wikaz.outils.notification.showWarning('Merci de renseigner une adresse Email valide!', 5.5)
            }
        },
    },
}
</script>